.h2 {
    font-family: 'Freshman', sans-serif;
}

.papel {
    width: 100%;
    height: auto;
    aspect-ratio: calc(1024/1738);
    background-image: url(/ui/src/temporada1/img/bg-papel-m.png);
}

.papel img.logo {
    width: auto;
    height: auto;
    aspect-ratio: calc(640/590);
    max-height: 20vh;
}

footer {
    height: auto;
}

footer .bg {
    z-index: 1;
    width: 100%;
    /* max-width: 768px; */
    height: auto;
    aspect-ratio: calc(768/1239);
    margin: 0 auto;
    object-fit: cover;
}

@media (min-width: 1024px) {
    .papel {
        aspect-ratio: calc(1280/429);
        background-image: url(/ui/src/temporada1/img/bg-papel.png);
    }    
    .papel img.logo {
        max-height: unset;
        width: 100%;
        aspect-ratio: calc(1372/480);
    }    
    footer .bg {
        z-index: 1;
        width: 100%;
        max-width: 1280px;
        height: auto;
        aspect-ratio: calc(1920/696);
        margin: 0 auto;
        object-fit: unset;
    }
}

.h2 {
  font-family: Freshman, sans-serif;
}

.papel {
  aspect-ratio: .589183;
  background-image: url("bg-papel-m.cc076e35.png");
  width: 100%;
  height: auto;
}

.papel img.logo {
  aspect-ratio: 1.08475;
  width: auto;
  height: auto;
  max-height: 20vh;
}

footer {
  height: auto;
}

footer .bg {
  z-index: 1;
  aspect-ratio: .619855;
  object-fit: cover;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media (width >= 1024px) {
  .papel {
    aspect-ratio: 2.98368;
    background-image: url("bg-papel.d18db8ea.png");
  }

  .papel img.logo {
    max-height: unset;
    aspect-ratio: 2.85833;
    width: 100%;
  }

  footer .bg {
    z-index: 1;
    aspect-ratio: 2.75862;
    object-fit: unset;
    width: 100%;
    max-width: 1280px;
    height: auto;
    margin: 0 auto;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-6 {
  right: 1.5rem;
}

.top-0 {
  top: 0;
}

.top-6 {
  top: 1.5rem;
}

.isolate {
  isolation: isolate;
}

.z-10 {
  z-index: 10;
}

.z-30 {
  z-index: 30;
}

.float-left {
  float: left;
}

.clear-both {
  clear: both;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.size-10 {
  width: 2.5rem;
  height: 2.5rem;
}

.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.h-10 {
  height: 2.5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-6 {
  height: 1.5rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.min-h-96 {
  min-height: 24rem;
}

.w-0 {
  width: 0;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-3\/4 {
  width: 75%;
}

.w-6 {
  width: 1.5rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.table-auto {
  table-layout: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-hidden {
  overflow: hidden;
}

.hyphens-auto {
  hyphens: auto;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-opacity-65 {
  --tw-bg-opacity: .65;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-contain {
  background-size: contain;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-2 {
  padding: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.\!leading-relaxed {
  line-height: 1.625 !important;
}

.leading-relaxed {
  line-height: 1.625;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity));
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

body {
  background: url("bg-s-01.7ce28163.jpg") center / cover no-repeat;
}

.gradient-bg {
  background: linear-gradient(0deg, #0003 20%, #000000b3 100%);
}

header nav a {
  font-family: COCOGOOSE, sans-serif;
}

#cardContainer {
  z-index: 99999 !important;
}

#BannersCarousel {
  z-index: 1000;
}

#BannersCarousel.swiper:not(.swiper-initialized) .swiper-slide {
  display: none;
}

#BannersCarousel.swiper:not(.swiper-initialized) .swiper-slide:first-child {
  display: block;
}

#BannersCarousel .swiper-slide {
  text-align: center;
}

#BannersCarousel .swiper-slide img {
  width: 100%;
  max-width: 1320px;
  height: auto;
  max-height: 645px;
}

#BannersCarousel .swiper-button-next, #BannersCarousel .swiper-button-prev {
  color: #15d602 !important;
}

#BannersCarousel .swiper-pagination-bullet-active {
  background-color: #15d602 !important;
}

.HomeSliders.default .swiper:not(.swiper-initialized) .swiper-wrapper {
  flex-wrap: wrap;
  max-height: 320px;
  display: flex;
  overflow: hidden;
}

.HomeSliders.default .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide {
  width: calc(25% - 8px);
  margin: 0 4px;
}

.HomeSliders.default .swiper img.swiper-lazy:not(.swiper-lazy-loaded) {
  max-height: 320px;
}

.HomeSliders.default {
  font-size: 1em;
  font-weight: 500;
  line-height: 1.2;
}

.HomeSliders.default .image {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 230px;
}

.HomeSliders.default a {
  color: var(--white);
  text-decoration: none;
  display: block;
  position: relative;
}

.HomeSliders.default a:hover {
  color: var(--white);
}

.HomeSliders.default .title {
  text-align: center;
  background-color: #000000b3;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 2.5em;
  padding: 0 .25em;
  font-size: .9em;
  font-weight: 600;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.swiper-button-next, .swiper-button-prev {
  color: #15d602 !important;
}

.swiper-pagination-bullet-active {
  background-color: #15d602 !important;
}

@media (width <= 1023px) {
  #BannersCarousel .swiper-slide img, .HomeSliders.default .swiper img.swiper-lazy:not(.swiper-lazy-loaded) {
    max-height: unset;
  }

  .noticia p {
    margin-left: .5em;
    margin-right: .5em;
  }
}

.noticia figure.image {
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.noticia figure.image img {
  display: block;
}

.noticia figure.image figcaption {
  caption-side: bottom;
  color: #000;
  background-color: #efefef;
  margin-bottom: .5em;
  padding: 3px;
  font-size: .9em;
  font-style: italic;
  display: table-caption;
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:z-20:focus {
  z-index: 20;
}

.focus\:outline-offset-0:focus {
  outline-offset: 0px;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-green-600:focus-visible {
  outline-color: #16a34a;
}

.focus-visible\:outline-indigo-600:focus-visible {
  outline-color: #4f46e5;
}

@media (width >= 640px) {
  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 768px) {
  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:max-w-screen-sm {
    max-width: 640px;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-72 {
    gap: 18rem;
  }

  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-16 {
    height: 4rem;
  }

  .lg\:h-32 {
    height: 8rem;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:text-end {
    text-align: end;
  }
}

@media (width >= 1280px) {
  .xl\:max-w-screen-lg {
    max-width: 1024px;
  }

  .xl\:bg-cover {
    background-size: cover;
  }
}
/*# sourceMappingURL=index.1f949abe.css.map */

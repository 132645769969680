@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(common.css);

body {
    background: url(../img/bg-s-01.jpg) no-repeat center/cover;
}

.gradient-bg {
    background: linear-gradient(0deg, rgba(0,0,0,0.2) 20%, rgba(0,0,0,0.7) 100%);
}

header nav a {
    font-family: 'COCOGOOSE', sans-serif;
}

#cardContainer {
    z-index: 99999 !important;
}

// 
// WIDGETS
// 

// Banners
#BannersCarousel {
    z-index: 1000;
}

#BannersCarousel.swiper:not(.swiper-initialized) .swiper-slide {
    display: none;
}

#BannersCarousel.swiper:not(.swiper-initialized) .swiper-slide:first-child {
    display: block;
}

#BannersCarousel .swiper-slide {
    text-align: center;
}

#BannersCarousel .swiper-slide img {
    width: 100%;
    max-width: 1320px;
    height: auto;
    max-height: 645px;
}

#BannersCarousel .swiper-button-next, 
#BannersCarousel .swiper-button-prev {
    color: #15d602 !important; 
}

#BannersCarousel .swiper-pagination-bullet-active {
    background-color: #15d602 !important; 
}

// Home Sliders
.HomeSliders.default .swiper:not(.swiper-initialized) .swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-height: 320px;
    overflow: hidden;
}

.HomeSliders.default .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide {
    margin: 0 4px;
    width: calc(25% - 8px);
}

.HomeSliders.default .swiper img.swiper-lazy:not(.swiper-lazy-loaded) {
    max-height: 320px;
}

.HomeSliders.default {
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2;
}

.HomeSliders.default .image {
  object-fit: cover;
  width: 100%;
  height: 230px;
  border-radius: 10px;
}

.HomeSliders.default a {
    color: var(--white);
    text-decoration: none;
    display: block;
    position: relative;
}

.HomeSliders.default a:hover {
    color: var(--white);
}

.HomeSliders.default .title {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 2.5em;
  background-color: rgba(0,0,0,0.7);
  text-align: center;
  font-size: 0.9em;
  font-weight: 600;
  padding: 0 0.25em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next, 
.swiper-button-prev  {
    color: #15d602 !important; 
}

.swiper-pagination-bullet-active {
    background-color: #15d602 !important; 
}

// @media screen and (max-width: 40em) and (orientation: portrait) {
@media (max-width: 1023px) {
    #BannersCarousel .swiper-slide img {
        max-height: unset;
    }
  .HomeSliders.default .swiper img.swiper-lazy:not(.swiper-lazy-loaded) {
    max-height: unset;
  }
  .noticia p {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

// noticia
.noticia figure.image {

}

.noticia figure.image {
    display: table;
    margin-left: auto;
    margin-right: auto;
}
 
.noticia figure.image img { 
    display: block;
}
 
.noticia figure.image figcaption {
    display: table-caption;
    caption-side: bottom;
    background-color: #efefef;
    color: #000;
    padding:3px;
    font-size: 0.9em;
    font-style: italic;
    margin-bottom: 0.5em;
}

